<template>
  <PageCard pageName="Groups" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <b-row class="search-container--groups">
        <b-col sm="12">
          <div class="search-container--bar">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search fa-lg"></i>
                </span>
              </div>
              <b-form-input
                v-focus
                id="prependedInput"
                class="form-control no-focus-highlt"
                size="lg"
                type="text"
                placeholder="Type Group Name to Select"
                @keyup.native="search"
                v-model="searchText"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="search-container--result std-border">
            <div>
              <v-wait for="reportsLoading">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="15"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div class="text-left">
                  <b-list-group v-if="emptyGroups" class="text-center">
                    <b-list-group-item>
                      <i>No Groups found ...</i>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group v-else>
                    <div v-for="(group, index) in filteredGroups" :key="index">
                      <b-list-group-item
                        @click="selectGroup(group)"
                        :class="[
                          { selected: group.id === selectedGroup.id },
                          'p-2',
                          'pl-4'
                        ]"
                        >{{ group.name }}</b-list-group-item
                      >
                    </div>
                  </b-list-group>
                </div>
              </v-wait>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="text-center pt-1">
        <b-col md="12">
          <router-link
            tag="button"
            :to="{ path: 'groups/new' }"
            class="btn btn-primary fw-btn mt-3 mx-3"
            >Create Group</router-link
          >
          <router-link
            tag="button"
            :to="{ path: `group/${selectedGroup.id}/edit` }"
            class="btn btn-primary fw-btn mt-3 mx-3"
            :disabled="!enableEdit"
            >Edit Group</router-link
          >
          <b-button
            size
            variant="secondary"
            :disabled="!selectedGroup.id"
            class="fw-btn mt-3 mx-3"
            @click="confirmModal"
            >Delete Group</b-button
          >
        </b-col>
      </b-row>
      <b-modal
        hide-header-close
        centered
        :title="`Delete ${selectedGroup.name} Group?`"
        ref="groupModal"
        @ok="deleteGroup"
        ok-variant="secondary"
        cancel-variant="light"
        >Are you sure you want to delete this group?</b-modal
      >
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import PageCard from '@/modules/core/components/layouts/PageCard'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'UserGroups',
  components: {
    PageCard
  },
  created() {
    this.fetchGroups()
  },
  data() {
    return {
      groups: [],
      searchText: '',
      filteredGroups: []
    }
  },
  computed: {
    ...mapGetters('Admin', ['selectedGroup']),
    ...mapGetters('Ability', ['editClientGroup']),
    emptyGroups() {
      return this.groups.length === 0
    },
    enableEdit() {
      if (!this.selectedGroup.id) {
        return false
      }
      if (this.selectedGroup.group_type !== 'Client Group') {
        return true
      }
      return this.editClientGroup
    }
  },
  methods: {
    ...mapMutations('Admin', ['setSelectedGroup']),
    search(event) {
      let text = event.target.value.toLowerCase()
      if (this.text === '') {
        this.filteredGroups = this.groups
      } else {
        this.filteredGroups = this.groups.filter(group => {
          return group.name.toLowerCase().indexOf(text) >= 0
        })
      }
    },
    selectGroup: function(group) {
      this.searchText = group.name
      this.setSelectedGroup(group)
    },
    confirmModal() {
      this.$refs.groupModal.show()
    },
    fetchGroups() {
      this.$http
        .get('/api/groups', {
          handleErrors: true
        })
        .then(res => {
          this.groups = res.data.groups
          this.filteredGroups = this.groups
        })
    },
    deleteGroup() {
      this.$http
        .delete('/api/groups/' + this.selectedGroup.id, {
          handleErrors: true
        })
        .then(res => {
          Vue.toasted.show(this.selectedGroup.name + res.data.message, {
            icon: 'user-circle',
            type: 'success'
          })
          this.fetchGroups()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.temp {
  min-height: 35rem;
}
</style>
